<!-- 工作台配置 -->
<template>
<div :class="['staging', noPadding ? 'noPadding' : '']">
  <img v-if="showSetting" src="@/images/manage_icon.png" class="setting" @click="setting">
  <van-search v-model="searchParams.name" :placeholder="$t('请输入应用名称')" @input="inputStr" />
  <div v-if="queryMark" class="content">
    <template v-if="!showSetting">
      <div class="title">
        <p>{{$t('常用功能')}}</p>
      </div>
      <div class="common-block">
        <div class="common-title-box">
          <div class="common-title-l">{{$t('常用功能')}}</div>
        </div>
        <div class="actions">
          <div v-for="(item, index) in commons" :key="index" class="action-i">
            <div class="image">
              <img :src="item.iconUrl" @click="goApplication(item)">
              <template v-if="!showSetting && item.choose && commons.length > 1">
                <div class="add_reduce" @click="deleteItem(item)">-</div>
              </template>
            </div>
            <div class="name">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </template>
    <div v-for="(item, index) in dataList" :key="index" class="common-block">
      <div class="common-title-box">
        <div class="common-title-l">{{ item.groupName }}</div>
      </div>
      <div class="actions">
        <div v-for="action in item.list" :key="action.name" class="action-i">
          <div class="image">
            <img :src="action.iconUrl" @click="goApplication(action)">
            <template v-if="!showSetting && action.choose">
              <div class="add_reduce" @click="addItem(action)">+</div>
            </template>
            <template v-if="showSetting && appToDo[action.code]">
              <div class="appToDo">{{ appToDo[action.code] > 99 ? '99+' : appToDo[action.code] }}</div>
            </template>
          </div>
          <div class="name">{{ action.name }}</div>
        </div>
      </div>
    </div>
    <div v-if="!showSetting" class="btns">
      <van-button class="btn" @click.stop="cancel">{{$t('取消')}}</van-button>
      <van-button class="btn submit" @click.stop="onSubmit">{{$t('确认')}}</van-button>
    </div>
  </div>
</div>
</template>
<script>
import salesAssistantService from '@/services/salesAssistant'
import { isUrl } from '@/utils'
import loading from '@/utils/loading'
export default {
  name: 'Staging',
  data() {
    return {
      showSetting: true,
      commons: [], // 常用功能
      commonsOld: [],
      resourcesOld: [],
      resources: [], // 全部功能
      searchParams: {
        name: '',
        appType: 1
      },
      queryMark: false,
      appToDo: {}
    }
  },
  computed: {
    dataList() {
      let arr = []
      this.resources.forEach(item => {
        item.choose = true
        if (this.commons.findIndex(({ code }) => { return code === item.code }) != -1) {
          item.choose = false
        }
        const index = arr.findIndex(({ groupName }) => { return groupName == item.groupName })
        if (index == -1) {
          let obj = {
            groupName: item.groupName,
            list: []
          }
          obj.list.push(item)
          arr.push(obj)
        } else {
          arr[index].list.push(item)
        }
      })
      return arr
    },
    noPadding() {
      return !this.$route.query.fromAssistant
    },
  },
  mounted() {
    const { setMark } = this.$route.query
    this.showSetting = setMark ? false : true
    this.getAllResource()
  },
  methods: {
    async getToDo(params) {
      const result = await salesAssistantService.appTodo(params)
      this.$set(this, 'appToDo', result)
    },
    async getAllResource() {
      loading.showLoading()
      const result = await salesAssistantService.allEmpResource(this.searchParams)
      let { commons=[], resources=[] } = result
      this.getToDo(Array.isArray(resources) ? resources.map(({ code }) => { return code }) : [])
      this.queryMark = true
      loading.hideLoading()
      // commons 常用功能 resources 全部
      const sortList = [this.$t('销售工具'), this.$t('企微运营'), this.$t('门店管理'), this.$t('培训学习')]
      resources = resources.sort((a,b) => {
        return sortList.indexOf(a.groupName) - sortList.indexOf(b.groupName)
      })
      this.commons = commons.map((item) => { return { choose: true, ...item } })
      this.resources = resources
      this.commonsOld = JSON.parse(JSON.stringify(commons.map((item) => { return { choose: true, ...item } })))
      this.resourcesOld = JSON.parse(JSON.stringify(this.resources))
    },
    setting() {
      this.showSetting = false
    },
    // 删除常用功能
    deleteItem(item) {
      this.commons = this.commons.filter(({ code }) => { return code != item.code })
    },
    // 添加至常用功能
    addItem(item) {
      if (this.commons.length > 3) {
        this.$toast(this.$t('至多添加4应用至常用功能'))
        return false
      }
      this.commons.push(Object.assign({ ...item, choose: true }))
    },
    cancel() {
      this.showSetting = true
      this.commons = JSON.parse(JSON.stringify(this.commonsOld))
      this.resources = JSON.parse(JSON.stringify(this.resourcesOld))
      // if (this.$route.query.fromAssistant) {
      //   this.$router.replace({
      //     path: '/salesAssistant',
      //     query: {
      //       replace: true
      //     }
      //   })
      // } else {
      //   this.$router.go(-1)
      // }
    },
    async onSubmit() {
      const params = this.commons.map(({ code }) => { return code })
      loading.showLoading()
      const result = await salesAssistantService.configEmpResource(params)
      loading.hideLoading()
      const { code, msg } = result
      if (code === 0) {
        this.$toast(this.$t('操作成功'))
        setTimeout(() => {
          if (window.history.length>1){
            this.$router.go(-1)
          }
        })
      } else {
        this.$toast.fail(msg || this.$t('操作失败'))
      }
    },
    // 跳转应用
    async goApplication({ code, resourcesUri }) {
      if (isUrl(resourcesUri)){
        // 外部链接 直接跳转
        window.location.href = resourcesUri
      } else if (resourcesUri) {
        this.$router.push({
          path: resourcesUri,
        })
      } else {
        this.$toast(this.$t('功能开发中 敬请期待！'))
      }
    },
    inputStr(value) {
      this.searchParams.name = value
      this.getAllResource()
    },
  }
}
</script>
<style lang="less" scoped>
.staging{
  position: relative;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: 96px;
  // height: 100vh;
  display: flex;
  flex-direction: column;
  &.noPadding{
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
  /deep/ .van-search{
    .van-search__content{
      border-radius: 20px;
    }
  }
  .content{
    display: flex;
    flex-direction: column;
    padding: 16px;
    flex: 1;
    overflow-y: auto;
    .title{
      font-size: 16px;
      margin-bottom: 12px;
      color: @black;
      .des{
        font-size: 12px;
        color: #969799;
      }
    }
    .common-block{
      display: flex;
      flex-direction: column;
      padding: 0 16px 16px 16px;
      .common-title-box{
        display: flex;
        height: 48px;
        align-items: center;
        box-sizing: border-box;
        justify-content: space-between;
        &.noBorder{
          border-bottom: none;
        }
        .common-title-l{
          padding-left: 8px;
          font-size: 14px;
          position: relative;
          &::before{
            content: ' ';
            left: 0;
            position: absolute;
            top: calc(50% - 6px);
            width: 2px;
            height: 12px;
            background: @yellow-text;
            border-radius: 0px 0px 0px 0px;
          }
        }
      }
      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
      .actions{
        display: flex;
        flex-wrap: wrap;
        height: auto;
        .action-i{
          width: 25%;
          height: 84px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .image{
            width: 36px;
            height: 36px;
            margin-bottom: 8px;
            position: relative;
            .add_reduce{
              position: absolute;
              right: 0;
              top: 0;
              width: 13px;
              height: 13px;
              border-radius: 8px;
              background: @black;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: -5px;
              margin-top: -5px;
              color: #fff;
            }
            .appToDo{
              position: absolute;
              right: 0;
              top: 0;
              width: 22px;
              height: 18px;
              border-radius: 18px;
              background: red;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: -8px;
              margin-top: -8px;
              color: #ffffff;
              font-size: 10px;
            }
            img{
              width: 36px;
              height: 36px;
            }
          }
          .name{
            white-space: wrap;
            text-align: center;
            font-size: 12px;
            line-height: 15px;
            height: 32px;
            margin-bottom: 8px;
          }
        }
      }
    }
    .btns{
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 0 16px;
      justify-content: space-between;
      font-size: 16px;
      color: @black;
      .btn{
        flex: 1;
        border: 1px solid @black;
        border-radius: 100px;
        cursor: pointer;
        &:not(:last-of-type) {
          margin-right: 8px;
        }
        &.submit{
          background: @yellow;
          border: none;
        }
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
  .setting{
    position: absolute;
    top: 50px;
    right: 16px;
    width: 46px;
    height: 46px;
    z-index: 10;
  }
}

</style>
